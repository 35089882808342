import { useTranslation } from 'react-i18next'

export const RenderLocalizedContent = ({
	en,
	sk,
}: {
	en: string
	sk: string
}) => {
	const { i18n } = useTranslation()
	const language = i18n.language

	return language === 'sk' ? sk : en
}
